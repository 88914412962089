import React, { useEffect, useContext, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BlogPost from "./blogPost"

export default ({
    title
}) => {
    const { nodes: _posts } = useStaticQuery(graphql`
		query {
			siteData {
				posts (
                    first: 5
                ) {
                    nodes {
                        title
                        excerpt
                        link
                        databaseId
                        categories {
                            nodes {
                                name
                            }
                        }
                        featuredImage {
                            node {
                                mediaItemUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                        }
                        fields {
                            hidePost
                        }
                    }
                }
			}
		}
    `).siteData.posts

    const posts = _posts.filter(post => !post.fields.hidePost)

    if(posts.length > 3){
        posts.length = 3
    }

    return (
        <div className={'container deep'}>
            <h3>
                {title}
            </h3>
            <ul className="blog-grid-list">
                {posts.map(post => {
                    return (
                        <BlogPost {...post} key={post.databaseId} />
                    )
                })}
            </ul>
        </div>
    )
}
