import React, { useEffect, useRef } from 'react'
import Slider from 'components/globals/slider'
import Link from 'components/globals/link'

export default ({
    bgRef,
    projects,
    isMobile,
    isScrolledIntoView
}) => {
    const bgOuter = useRef()
    const sliderInstance = useRef()

    useEffect(() => {
        if(sliderInstance.current){
            sliderInstance.current.setRendering(isScrolledIntoView)
        }
    }, [isScrolledIntoView])

    return (
        <div className="bg-outer" id="bg-outer" ref={bgOuter}>
            <Slider
                className={'bg'}
                options={{
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: 'center',
                    wrapAround: true,
                    contain: true,
                }}
                flickityRef={ref => bgRef.current = ref}
            >
                {projects.map(({ project, image, mobileImage, url }, index) => {
                    let {
                        featuredImage,
                        link
                    } = project || {
                        featuredImage: '',
                        link: ''
                    }

                    if (url) {
                        link = url
                    }

                    const bgImage = image ? image.mediaItemUrl : featuredImage ? featuredImage.node.mediaItemUrl : ''
                    const mobileBgImage = mobileImage ? mobileImage.mediaItemUrl : image ? image.mediaItemUrl : featuredImage ? featuredImage.node.mediaItemUrl : ''

                    return (
                        <li
                            key={index}
                            className={'force-cursor-white has-cursor-effect show-drag-cursor plane'}
                        >
                            <img
                                src={isMobile ? mobileBgImage : bgImage}
                            />
                            <Link to={link} />
                        </li>
                    )
                })}
            </Slider>
        </div>
    )
}
