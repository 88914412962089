import React from 'react'
import Link from 'components/globals/link'
import Button from 'components/globals/button'

export default ({
    title,
    excerpt,
    featuredImage,
    categories,
    link,
    list,
    fields,
    forceVisible
}) => {
    const image = (() => {
        if (featuredImage && featuredImage.node && featuredImage.node.mediaItemUrl) {
            return featuredImage.node.mediaItemUrl
        }

        if (fields.heroImage) {
            return fields.heroImage.mediaItemUrl
        }

        return ''
    })()

    const categoryName = categories.nodes[0].name

    return (
        <li className="has-cursor-effect blog-index-post">
            <div
                className={`blog-post-content ${ forceVisible ? 'is-inview' : '' }`}
                data-scroll
                data-scroll-speed={list ? 0 : 2}
            >
                <div className={'inner'}>
                    <p>
                        {categoryName}
                    </p>
                    <p>
                        { fields.author }
                    </p>
                    <Link to={link}>
                        <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    </Link>
                    {!list &&
                        <>
                            <div className={'copy'} dangerouslySetInnerHTML={{ __html: excerpt }} />
                            <Button
                                label="Read More"
                                inverted
                                icon="plus"
                                url={link}
                            />
                        </>
                    }
                </div>
            </div>
            <Link className={'thumbnail'} to={link}>
                <div
                    data-scroll
                    data-scroll-speed={list ? 0 : 1}
                    className={`${ forceVisible ? 'is-inview' : '' }`}
                >
                    <img
                        src={image}
                        className={`inner ${list ? "blog-remainder-image" : "blog-first-image"}`}
                        alt={`${ categoryName } blog image to support ${ title }`}
                    />
                </div>
            </Link>
        </li>
    )
}