import React, { useState, useContext, useRef, useEffect } from 'react'
import Link from 'components/globals/link'
import { MainContext } from 'context/context'

export default ({
    posts: _posts
}) => {
    const posts = _posts.filter(post => !post.post.fields.hidePost)
    
    const [activeIndex, setActiveIndex] = useState(0)
    const activeIndexRef = useRef(activeIndex)

    activeIndexRef.current = activeIndex

    const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const elementRef = useRef(null)
    const swapIntervalRef = useRef(null)

    useEffect(() => {
        if (elementRef && elementRef.current) {
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    const startAutoplay = () => {
        swapIntervalRef.current = setInterval(() => {
            const newIndex = posts[(activeIndexRef.current + 1)] ? activeIndexRef.current + 1 : 0

            setActiveIndex(newIndex)
        },3000)
    }

    const pauseAutoplay = () => {
        clearInterval(swapIntervalRef.current)
    }

    useEffect(() => {
        startAutoplay()

        return () => {
            clearInterval(startAutoplay)
        }
    }, [])

    return (
        <>
            <ul className={'backgrounds'} ref={ref => elementRef.current = ref}>
                {posts.map((item, index) => {
                    const {
                        post: {
                            fields,
                            featuredImage
                        }
                    } = item

                    let image = ''

                    if(fields.heroImage && fields.heroImage.mediaItemUrl){
                        image = fields.heroImage.mediaItemUrl
                    }else if(featuredImage && featuredImage.node){
                        image = featuredImage.node.mediaItemUrl
                    }

                    return (
                        <li 
                            key={index}
                            className={activeIndex === index ? 'active' : ''}
                            style={{
                                backgroundImage: `url(${ image })`
                            }}
                        />
                    )
                })}
            </ul>
            <div className={'container deep inverted-cursor'}>
                <div>
                    <h1>
                        {posts.map((post, index) => {
                            return (
                                <Link 
                                    key={index} 
                                    className={'has-cursor-effect'}
                                    to={post.post.link}
                                >
                                    <span 
                                        dangerouslySetInnerHTML={{ __html: post.sentence }} 
                                        onMouseEnter={() => {
                                            pauseAutoplay()
                                            setActiveIndex(index)
                                        }}
                                        onMouseLeave={() => {
                                            startAutoplay()
                                        }}
                                        className={activeIndex === index ? 'active' : ''}
                                    />
                                </Link>
                            )
                        })}
                    </h1>
                    <ul className={'titles'}>
                        { posts.map((post, index) => {
                            return (
                                <li key={index} className={activeIndex === index ? 'active' : ''}>
                                    { post.post.title }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}