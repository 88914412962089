import React, { useRef, useContext, useEffect, useState } from 'react'
import Slider from 'components/globals/slider'
import Link from 'components/globals/link'
import { MainContext } from 'context/context'

import Button from 'components/globals/button'

import { useIsTablet } from 'utils'
import ProjectSlideshowBg from './projectSlideshowBg'

export default ({
    projects,
    whiteBackground,
    isScrolledIntoView
}) => {
    const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const [hasMounted, setHasMounted] = useState(false)

    const isMobile = useIsTablet()
    const isMobileRef = useRef(isMobile)
    isMobileRef.current = isMobile

    const elementRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        }, 10)
    }, [])

    useEffect(() => {
        if (elementRef && elementRef.current && !whiteBackground) {
            addInvertedHeaderSection(elementRef.current)

            setTimeout(() => {
                if (window.cursorHandler) {
                    if (elementRef.current) {
                        window.cursorHandler.attachChildListeners(elementRef.current)
                    }
                    if (parentRef.current) {
                        window.cursorHandler.attachChildListeners(parentRef.current)
                    }
                }

                const elementRefParentTransform = elementRef.current.parentElement.getBoundingClientRect()

                if (parentRef.current) {
                    parentRef.current.style.height = `${elementRefParentTransform.height + 20}px`
                    parentRef.current.style.transform = `translateY(${elementRefParentTransform.top}px)`
                }
            }, 1000)
        }
    }, [elementRef.current])

    const bgRef = useRef(null)
    const fgRef = useRef(null)
    const parentRef = useRef()

    useEffect(() => {
        if (fgRef.current && bgRef.current) {
            fgRef.current.on('change', newIndex => {
                bgRef.current.select(newIndex)
            })

            bgRef.current.on('change', newIndex => {
                fgRef.current.select(newIndex)
            })
        }
    }, [fgRef.current])

    const hasContent = projects.find(project => (project.description || project.project || project.title))

    if (!hasMounted) {
        return null
    }

    const contentJsx = hasContent ? (
        <Slider
            className={'fg'}
            options={{
                prevNextButtons: false,
                pageDots: true,
                cellAlign: 'left',
                wrapAround: true,
                contain: true,
                adaptiveHeight: true,
                fade: !isMobile
            }}
            flickityRef={ref => fgRef.current = ref}
        >
            {projects.map(({
                project,
                description,
                title,
                url
            }, index) => {
                let {
                    title: _title,
                    link,
                } = project || {
                    title,
                    link: '',
                }

                if (url) {
                    link = url
                }

                return (
                    <li key={index} className={'has-cursor-effect'}>
                        <span className="h1">
                            <Link to={link}>
                                {_title}
                            </Link>
                        </span>
                        <div
                            className={'copy'}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                        <div className="button-container">
                            <Button
                                label={url ? `View website` : `View case study`}
                                url={link}
                                compact
                                icon={'arrow-right'}
                                iconRight
                                noMagnetism
                            />
                        </div>
                    </li>
                )
            })}
        </Slider>
    ) : null

    return (
        <div className={`inner ${whiteBackground ? 'white-background' : ''} ${!hasContent ? 'no-content' : ''}`}>
            <div className={`container deep inverted-cursor`} ref={ref => elementRef.current = ref}>
                {contentJsx}
                <div>
                    <ProjectSlideshowBg
                        bgRef={bgRef}
                        projects={projects}
                        parentRef={parentRef}
                        isMobile={isMobile}
                        isScrolledIntoView={isScrolledIntoView}
                    />
                </div>
            </div>
        </div>
    )
}
