import React, { useRef, useEffect, useState } from 'react'
import Slider from 'components/globals/slider'

export default ({
    title,
    images
}) => {
    const sliderRef = useRef(null)
    const [activeSlide, setActiveSlide] = useState(0)

    useEffect(() => {
        if(sliderRef){
            sliderRef.current.on('change', newIndex => {
                setActiveSlide(newIndex)
            })
        }
    }, [sliderRef])

    return (
        <div>
            <div className={'container deep'}>
                <h4 dangerouslySetInnerHTML={{ __html: title }} />
                <Slider
                    className={'bg'}
                    options={{
                        fade: true,
                        prevNextButtons: false,
                        pageDots: true,
                        cellAlign: 'left',
                        wrapAround: true,
                        contain: true,
                        autoPlay: 5000
                    }}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    {images.map((item, index) => {
                        const {
                            image,
                            copy
                        } = item

                        return (
                            <li
                                key={index}
                            >
                                <div className={`progress ${ activeSlide === index ? 'active' : '' }`} />
                                <div className={'bg'} style={{ backgroundImage: `url(${image.mediaItemUrl})` }} />
                                <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
                            </li>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}