import React from 'react'

export default ({
    title,
    logos
}) => {
    return (
        <div className={'container deep'}>
            <p className={'h1'} dangerouslySetInnerHTML={{ __html: title }} />
            <ul className={'grid'}>
                {logos.map((logo, index) => {
                    const {
                        image,
                        link
                    } = logo

                    if (!image || !image.mediaItemUrl) {
                        return null
                    }

                    return (
                        <li
                            key={index}
                            style={{
                                transitionDelay: (index / 15) + 's'
                            }}
                        >
                            <a href={(link && link.url) ? link.url : ''} target="_blank">
                                <div className={'image'}>
                                    <img src={image.mediaItemUrl} alt={(link && link.title) ? `Logo for ${link.title}` : ``} />
                                </div>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}