import React from 'react'

export default ({
    projects
}) => {
    return (
        <div className={'container deep'}>
            <ul>
                {projects.map(({ project }, index) => {
                    const {
                        title,
                        featuredImage
                    } = project

                    return (
                        <li key={index}>
                            <div>
                                <div className={'image'}>
                                    <img src={featuredImage.node.mediaItemUrl} />
                                </div>
                                <div className="content">
                                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                    <span className={'subtitle'}>
                                        Web app &bull; React &amp; Laravel
                                    </span>
                                    <div className={'copy'}>
                                        <p>
                                            Lorem ipsum dolor sit amet. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}