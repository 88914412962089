import React from 'react'

export default ({
    items
}) => {
    return (
        <div>
            <div className={'container deep'}>
                <ul>
                    { items.map((item, index) => {
                        const {
                            icon,
                            title,
                            content
                        } = item

                        return (
                            <li 
                                key={index}
                                style={{
                                    transitionDelay: (index/10)+'s'
                                }}
                            >
                                <div className={'image-container'}>
                                    <div className={'image'} style={{
                                        backgroundImage: `url(${ icon.mediaItemUrl })`
                                    }} />
                                </div>
                                <div className={'h1'} dangerouslySetInnerHTML={{ __html: title }} />
                                <div className={'copy'} dangerouslySetInnerHTML={{ __html: content }} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}