import React, { useRef, useState } from 'react'

import Swal from 'sweetalert2'

import {
    SUBMIT_FORM
} from '../../graphql/queries'

import { useMutation } from '@apollo/client'

import TextareaAutosize from 'react-textarea-autosize'
import Button from 'components/globals/button'
import Loader from 'components/globals/loader'
import Fadecollapse from 'components/globals/fadeCollapse'

const FormSection = ({
    title,
    copy,
    form
}) => {
    const [formValues, setFormValues] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const formValuesRef = useRef(formValues)
    const [response, setResponse] = useState(null)

    const isSoldOut = true

    const [submitForm] = useMutation(SUBMIT_FORM)

    formValuesRef.current = formValues

    const handleChange = (key, value) => {
        setFormValues({
            ...formValues,
            [key]: value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()

        if(isSoldOut){
            return
        }

        const columnKeys = []

        form.formFields.rows.forEach(row => {
            row.columns.forEach(column => {
                columnKeys.push(column.label)
            })
        })

        let error = null

        columnKeys.some(key => {
            if (~key.toLowerCase().indexOf('optional')) {
                return
            }
            if (!formValuesRef.current[key]) {
                error = true
                return true
            }
        })

        if (error) {
            setResponse({
                type: 'error',
                message: `One or more fields are missing.`
            })

            return setTimeout(() => {
                setResponse(null)
            }, 3000)
        }

        const _values = JSON.stringify(formValuesRef.current)

        setIsLoading(true)

        submitForm({
            variables: {
                data: _values,
                title: form.title
            },
        })

        setTimeout(() => {
            const name = formValuesRef.current['Your name']
            const firstName = name.split(' ')[0]

            Swal.fire({
                icon: 'success',
                title: `Thanks for that, ${firstName}!`,
                text: `We've received your registration, and will be in touch over the coming days.`,
            })

            setFormValues({})
            setIsLoading(false)

            setResponse({
                type: 'success',
                message: `Success! We've received your registration.`
            })
        }, 500)
    }

    return (
        <section className='formSection container deep'>
            <div>
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                <div className='copy'>
                    <div dangerouslySetInnerHTML={{ __html: copy }} />
                </div>
            </div>
            <div>
                <form onSubmit={handleSubmit}>
                    {isLoading ?
                        <Loader visible={false} />
                        : null}
                    {isSoldOut ?
                        <div className='sold-out'>
                            <h2>
                                This event is fully booked, unfortunately!
                            </h2>
                            <p>
                                Stay in touch on our socials for more 😊.
                            </p>
                        </div>
                        : null}
                    <h3>
                        Register for this event here:
                    </h3>
                    <Fadecollapse open={!!response}>
                        <div className={`validate-response ${response ? 'visible' : ''}`}>
                            {response &&
                                <>
                                    <i className={`fe fe-${response.type === 'success' ? 'check' : 'x'}`} />
                                    <span>
                                        {response.message}
                                    </span>
                                </>
                            }
                        </div>
                    </Fadecollapse>
                    <ul>
                        {form.formFields.rows.map(({ columns }, index) => {
                            return (
                                <li key={index} className={`row columns-${columns.length}`}>
                                    {columns.map((column, columnIndex) => {
                                        const {
                                            label,
                                            options,
                                            type
                                        } = column

                                        let component = null

                                        switch (type) {
                                            case 'text':
                                            case 'email':
                                                component = (
                                                    <input
                                                        type={type}
                                                        value={formValues[label] || ''}
                                                        onChange={e => handleChange(label, e.target.value)}
                                                    />
                                                )
                                                break
                                            case 'textarea':
                                                component = (
                                                    <TextareaAutosize
                                                        value={formValues[label] || ''}
                                                        onChange={e => handleChange(label, e.target.value)}
                                                    />
                                                )
                                                break
                                            case 'radio':
                                                const _options = options.split(/\n/).filter(Boolean).map(option => option.trim())

                                                component = (
                                                    <div className={'radios'}>
                                                        <span dangerouslySetInnerHTML={{ __html: label + ':' }} />
                                                        <div className={'options'}>
                                                            {_options.map((option, optionIndex) => {
                                                                return (
                                                                    <label key={optionIndex}>
                                                                        <input
                                                                            type={'radio'}
                                                                            name={label}
                                                                            checked={formValues[label] === option}
                                                                            onChange={() => handleChange(label, option)}
                                                                        />
                                                                        <span>
                                                                            {option}
                                                                        </span>
                                                                    </label>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                                break
                                        }

                                        return (
                                            <div key={columnIndex}>
                                                <label>
                                                    {label}
                                                </label>
                                                {component}
                                            </div>
                                        )
                                    })}

                                </li>
                            )
                        })}
                    </ul>
                    <div className='button-row'>
                        <Button
                            label={form.formFields.buttonLabel}
                            type="button"
                            compact
                            icon={'plus'}
                        />
                    </div>
                </form>
            </div>
        </section>
    )
}

export default FormSection