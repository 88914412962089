import React from "react"
import { graphql } from "gatsby"

import FlexibleContent from "components/globals/flexibleContent"

import PageWrapper from 'components/globals/pageWrapper'

export default ({ data, pageContext }) => {
	const {
		databaseId,
		slug,
		title,
		excerpt,
		flexibleContent,
		seoFields
	} = data.siteData.page

	const _title = (seoFields && seoFields.metaTitle) ? seoFields.metaTitle : title
	const _description = (seoFields && seoFields.metaDescription) ? seoFields.metaDescription : excerpt
	const _image = (seoFields && seoFields.ogpImage) ? seoFields.ogpImage.mediaItemUrl : null

	return (
		<>
			<PageWrapper
				title={_title}
				description={_description}
				image={_image}
			>
				<FlexibleContent
					id={databaseId}
					content={flexibleContent}
					slug={slug}
					postType={pageContext.type}
				/>
			</PageWrapper>
		</>
	)
}

export const query = graphql`
	query($databaseId: ID!) {
		siteData {
			page(id: $databaseId, idType: DATABASE_ID) {
				databaseId
				title
				slug
				date
				content(format: RENDERED)
				seoFields {
					metaTitle
					metaDescription
					ogpImage {
						mediaItemUrl
					}
				}
				flexibleContent {
					... FlexibleContentFragment
				}
			}
		}
	}
`
