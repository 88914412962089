import React, { useRef, useContext, useEffect } from 'react'
import TwoColumnCopy from 'components/flexibleContent/twoColumnCopy'
import { MainContext } from 'context/context'

import { useStaticQuery, graphql } from "gatsby"
import { chunk } from 'lodash'

import { useIsMobile } from 'utils'

export default ({
    title,
    copy
}) => {
    const { nodes: people } = useStaticQuery(graphql`
		query {
			siteData {
				people(first: 50) {
                    nodes {
                        title
                        fields {
                            role
                            copy
                            colour
                        }
                        featuredImage {
                            node {
                                mediaItemUrl
                            }
                        }
                    }
                }
			}
		}
    `).siteData.people

    const isMobile = useIsMobile()

    const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const elementRef = useRef(null)

    useEffect(() => {
        if (elementRef && elementRef.current) {
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    const peopleRows = chunk(people, isMobile ? 999 : 3)

    return (
        <div className={'inverted-cursor'} ref={ref => elementRef.current = ref}>
            <section className="TwoColumnCopy">
                <TwoColumnCopy
                    left={title}
                    right={copy}
                />
            </section>
            <ul className={'team-rows'}>
                {peopleRows.map((row, index) => {
                    const isOdd = !!(index % 2)

                    return (
                        <li className={'container deep'} key={index}>
                            <ul
                                className={'row'}
                                data-scroll
                                data-scroll-speed={isOdd ? -1 : 1}
                                data-scroll-direction="horizontal"
                            >
                                {!isMobile ?
                                    <li className={'dummy'}>
                                        <div className={'image'} />
                                    </li>
                                    : null}
                                {row.map((person, personIndex) => {
                                    const {
                                        title,
                                        fields: {
                                            role,
                                            copy,
                                            colour
                                        },
                                        featuredImage: _featuredImage
                                    } = person

                                    if (!_featuredImage) {
                                        return null
                                    }

                                    const {
                                        node: {
                                            mediaItemUrl: featuredImage
                                        }
                                    } = _featuredImage

                                    return (
                                        <li 
                                            key={personIndex}
                                            data-scroll
                                            data-scroll-offset={'5%, 5%'}
                                            className={`${ !title ? ' placeholder' : '' }`}
                                        >
                                            <div className={`image ${colour}`}>
                                                <span>
                                                    <div className={'copy huge'} dangerouslySetInnerHTML={{ __html: copy }} />
                                                </span>
                                                <div className={'image-inner'} style={{
                                                    backgroundImage: `url(${featuredImage})`
                                                }} />
                                            </div>
                                            <div className={'content'}>
                                                <h4 dangerouslySetInnerHTML={{ __html: title }} />
                                                <div className={'copy medium'} dangerouslySetInnerHTML={{ __html: role }} />
                                            </div>
                                        </li>
                                    )
                                })}
                                {!isMobile ?
                                    <li className={'dummy'}>
                                        <div className={'image'} />
                                    </li>
                                    : null}
                            </ul>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}