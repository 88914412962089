import React, { useState, useRef } from 'react'
import FadeCollapse from 'components/globals/fadeCollapse'
import { cloneDeep } from 'lodash'

import Link from 'components/globals/link'

export default ({
    columns
}) => {
    const [openIds, setOpenIds] = useState([])
    const openIdsRef = useRef(openIds)

    openIdsRef.current = openIds

    const handleToggle = id => {
        const indexIfExists = openIdsRef.current.indexOf(id)

        const _openIds = cloneDeep(openIds)

        if (indexIfExists === -1) {
            _openIds.push(id)
        } else {
            _openIds.splice(indexIfExists, 1)
        }

        setOpenIds(_openIds)
    }

    return (
        <div className={'container deep'}>
            <ul className={'columns'}>
                {columns.map((column, index) => {
                    const {
                        title,
                        items,
                        service
                    } = column

                    return (
                        <li key={index}>
                            <div>
                                { service ?
                                    <Link className={'has-cursor-effect'} to={service.link}>
                                        <h3>
                                            { service.title }
                                        </h3>
                                    </Link>
                                : title ?
                                    <h3>
                                        {title}
                                    </h3>
                                : null
                                }
                                <ul className={'rows'}>
                                    {items.map((item, itemIndex) => {
                                        const {
                                            title: itemTitle,
                                            copy
                                        } = item

                                        const id = `${index}_${itemIndex}`

                                        const isOpen = openIds.indexOf(id) !== -1

                                        return (
                                            <li className={isOpen ? 'open' : ''} key={itemIndex}>
                                                <button className={'has-cursor-effect'} onClick={() => handleToggle(id)}>
                                                    <i className={'plus'} />
                                                    <p className={'big'} dangerouslySetInnerHTML={{ __html: itemTitle }} />
                                                </button>
                                                <FadeCollapse open={isOpen}>
                                                    <div className={'copy medium'} dangerouslySetInnerHTML={{ __html: copy }} />
                                                </FadeCollapse>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
