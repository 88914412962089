import React, { useEffect, useRef, useState } from 'react'
import Button from 'components/globals/button'
import { invlerp, useThrottledHandleScroll } from 'utils/index'
import { useIsMobile } from 'utils/index'

import ExpandingDivider from 'images/expanding-divider.png'

export default function LabsExpandingItem({
	isLaptop,
	image,
	video,
	title,
	subtitle,
	backgroundColour,
	backgroundImage,
	button,
	index
}) {
	const isMobile = useIsMobile()
	const isMobileRef = useRef(isMobile)

	isMobileRef.current = isMobile

	const _window = typeof window !== 'undefined' ? window : null
	const scrollerInstance = _window ? _window.scrollerInstance : null
	const [open, setOpen] = useState(false)
	const openRef = useRef(open)
	openRef.current = open

	const sidesRefs = useRef([])

	const containerRef = useRef()
	const imageRef = useRef()

	const scrollBasedExpansion = e => {
		const initWidth = 30
		const fullWidth = isMobileRef.current ? 90 : 98

		const { current: currentElement } = containerRef
		const { current: imageElement } = imageRef
		const { current: sides } = sidesRefs

		if(!currentElement){
			return 
		}

		const elementBounding = currentElement.getBoundingClientRect()

		const midPoint = (elementBounding.height / 2) + elementBounding.top

		const midPointToScreen = Math.abs(invlerp(0, _window.innerHeight, midPoint) - 0.5) * 1.5

		const delta = invlerp(0.7, 0.2, midPointToScreen)

		const newWidth = ((fullWidth - initWidth) * delta) + initWidth

		const _open = newWidth > 80

		if (_open !== openRef.current) {
			setOpen(_open)
		}

		if (isLaptop) {
			imageElement.style.opacity = delta + (isMobileRef.current ? 0 : 0.4)
			imageElement.style.transform = `translateX(${-50 + (delta * 5)}%)`
		}

		sides.forEach((side, sideIndex) => {
			if(isMobileRef.current){
				const widthPx = (5 * newWidth)
				side.style.transform = `translateX(${(widthPx / 2) * (sideIndex ? 1 : -1)}px)`
			}else{
				side.style.transform = `translateX(${(newWidth / 2) * (sideIndex ? 1 : -1)}vw)`
			}
		})
	}

	useEffect(() => {
		if (scrollerInstance) {
			scrollerInstance.on('scroll', throttleHandleScrollExpansion.current)
		}
	}, [scrollerInstance])

	const throttleHandleScrollExpansion = useThrottledHandleScroll(scrollBasedExpansion, 15)

	const id = `expanding-item-${index}`

	return (
		<div className="LabsExpandingItem-cont inverted-cursor" id={id}>
			<div className={'LabsExpandingItem-outerWrapper'}
				data-scroll
				data-scroll-sticky
				data-scroll-target={`#${id}`}
			>
				{['left', 'right'].map((side, sideIndex) => (
					<div key={side} ref={ref => sidesRefs.current[sideIndex] = ref} className={`side ${ side }`}>
						<img src={ExpandingDivider} />
					</div>
				))}
				<div
					className={`LabsExpandingItem-innerWrapper ${open ? 'open' : ''}`}
					ref={containerRef}
					style={{ background: backgroundColour }}
				>
					<img
						src={backgroundImage.mediaItemUrl}
						className={'bg'}
						data-scroll
						data-scroll-speed={2}
					/>
					<div className='LabsExpandingItem-inner container deep'>
						<div className='LabsExpandingItem-content has-cursor-effect'>
							{subtitle &&
								<div className='copy medium' dangerouslySetInnerHTML={{ __html: subtitle }} />
							}
							{title &&
								<div
									className='copy huge'
									dangerouslySetInnerHTML={{ __html: title }}
								/>
							}
							{button &&
								<div className={'button-container'}>
									<Button label={button.title} url={button.url} compact />
								</div>
							}
						</div>
						<div className={`LabsExpandingItem-media ${isLaptop ? 'laptop' : ''}`}>
							{image ?
								<div ref={imageRef} className={'image-container'}>
									<img
										src={image.mediaItemUrl}
										alt={subtitle}
									/>
								</div>
								: video ?
									null : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
