import React, { useRef, useContext, useEffect } from 'react'
import { times } from 'lodash'
import Button from 'components/globals/button'
import { MainContext } from 'context/context'

import { useIsMobile } from 'utils'

export default ({
    images,
    title,
    buttonText,
    backgroundVideo
}) => {
    const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const elementRef = useRef(null)

    useEffect(() => {
        if (elementRef && elementRef.current) {
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    const isMobile = useIsMobile()

    const itemsRef = useRef((() => {
        const maxItems = 20

        let incrementer = 0

        const _items = []

        while (_items.length < maxItems) {
            if (!images[incrementer]) {
                incrementer = 0
            }

            const _image = (() => {
                const item = images[incrementer].image

                const {
                    mediaItemUrl,
                    mediaDetails
                } = item

                let _response = null

                mediaDetails.sizes.forEach(size => {
                    if(size.name === 'medium_large'){
                        _response = size.sourceUrl
                    }
                })

                if(!_response){
                    _response = mediaItemUrl
                }

                return _response
            })()

            images[incrementer].image && _items.push(_image)

            incrementer++
        }

        return _items
    })())

    let renderedImageIndex = -1

    const doRow = ignoredIndices => {
        return (
            <>
                { times(6, index => {
                    const shouldRenderImage = ignoredIndices.indexOf(index) === -1

                    if (shouldRenderImage) {
                        renderedImageIndex++
                    }

                    return (
                        <div
                            className="bg-image"
                            key={index}
                            style={{
                                backgroundImage: shouldRenderImage ? `url(${itemsRef.current[renderedImageIndex]})` : undefined
                            }}
                        />
                    )
                })}
            </>
        )
    }

    const rows = [
        {
            scrollSpeend: "-1",
            ignoredIndices: [0, 1, 2, 5]
        },
        {
            scrollSpeend: "1",
            ignoredIndices: [0, 5]
        },
        {
            scrollSpeend: "-1",
            ignoredIndices: [5]
        },
        {
            scrollSpeend: "1",
            ignoredIndices: [0, 5]
        },
    ]

    return (
        <>
            { isMobile ?
                <video muted loop autoPlay playsInline>
                    <source src={backgroundVideo.mediaItemUrl} type="video/mp4" />
                </video>
            : null}
            <div className={'dark inverted-cursor'} ref={ref => elementRef.current = ref}>
                <div
                    className={'overlay'}
                >
                    <p
                        className={'h1'}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <div
                        className={'button-container'}
                    >
                        <Button
                            label={buttonText}
                            icon={'play-circle'}
                            url={'#openShowreel'}
                        />
                    </div>
                </div>
                {!isMobile ?
                    <section className="tiles tiles--rotated" id="grid2">
                        <div className="tiles__wrap">
                            {rows.map((row, index) => {
                                const {
                                    scrollSpeend,
                                    ignoredIndices
                                } = row

                                return (
                                    <div
                                        className="tiles__line"
                                        data-scroll
                                        data-scroll-speed={scrollSpeend}
                                        data-scroll-target="#grid2"
                                        data-scroll-direction="horizontal"
                                        key={index}
                                    >
                                        { doRow(ignoredIndices)}
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                : null}
            </div>
        </>
    )
}