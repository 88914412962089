import React, { useEffect } from 'react'
import AccordionColumns from './accordionColumns'

export default function TextAndAccordion({
	title,
	copy,
	accordionItems,
	backgroundImage
}) {
	const items = [{
		items: accordionItems
	}]

	return (
		<div className='container deep inverted-cursor'>
			<div className='left'>
				{title &&
					<div className='h1' dangerouslySetInnerHTML={{__html: title}} />
				}
				{copy &&
					<div className='copy' dangerouslySetInnerHTML={{__html: copy}} />
				}
			</div>
			<div className='right'>
				<section className='AccordionColumns single scrolled-into-view'>
					<AccordionColumns columns={items} />
				</section>
			</div>
			{ backgroundImage &&
				<img 
					src={backgroundImage.mediaItemUrl} 
					className={'bg'} 
					data-scroll
					data-scroll-speed={2}
				/>
			}
		</div>
	)
}
