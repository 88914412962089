import React, { useEffect, useState } from 'react'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'

import { stripHtml } from 'utils'

export default function LabsDualTicker({
	lineOneItems,
	lineTwoItems,
	preTitle
}) {
	const [hasMounted, setHasMounted] = useState(false)
	const [pageIsVisible, setPageIsVisible] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setHasMounted(true)
		}, 1000)
	}, [])

	const handleVisibilityChange = isVisible => {
		setPageIsVisible(isVisible)
	}

	const doRow = items => {
		return items.concat(items).map(({
			text,
			logo
		}, index) => (
			<li key={index}>
				<img
					src={logo.mediaItemUrl}
					alt={`Software development and product development in ${stripHtml(text)}`}
				/>
			</li>
		))
	}

	const firstLineItems = doRow(lineOneItems)
	const secondLineItems = doRow(lineTwoItems)

	return (
		<div className={'inverted-cursor'}>
			<h3 dangerouslySetInnerHTML={{ __html: preTitle }} />
			{!hasMounted ?
				<div style={{
					opacity: 0
				}}>
					{firstLineItems}
					{secondLineItems}
				</div>
				:
				<div>
					<PageVisibility onChange={handleVisibilityChange}>
						{pageIsVisible && (
							<>
								<Ticker>
									{() => {
										return firstLineItems
									}}
								</Ticker>
								<Ticker direction={'toRight'}>
									{() => {
										return secondLineItems
									}}
								</Ticker>
							</>
						)}
					</PageVisibility>
				</div>
			}
		</div>
	)
}
