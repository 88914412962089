import React, { useState, useEffect, useRef, useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BlogIndexPost from './blogIndexPost'
import { MainContext } from 'context/context'

import Button from 'components/globals/button'

export default () => {
    const { nodes: posts } = useStaticQuery(graphql`
		query {
			siteData {
				posts {
                    nodes {
                        title
                        excerpt
                        categories {
                            nodes {
                                name
                            }
                        }
                        link
                        featuredImage {
                            node {
                                mediaItemUrl
                            }
                        }
                        fields {
                            author
                            heroImage {
                                mediaItemUrl
                            }
                            hidePost
                        }
                    }
                }
			}
		}
    `).siteData.posts

    const {
        recalcLocomotive
    } = useContext(MainContext)

    const _posts = posts.filter(post => !post.fields.hidePost)

    const firstThreePosts = _posts.slice(0, 3)
    const remainingPosts = _posts.slice(3)

    const paginate = 5

    const remainderRef = useRef(null)
    const [remainderPostsVisible, setRemainderPostsVisible] = useState(paginate)
    const remainderPostsVisibleRef = useRef(remainderPostsVisible)
    remainderPostsVisibleRef.current = remainderPostsVisible

    const [remainderHeight, setRemainderHeight] = useState(0)

    const hasMorePosts = remainingPosts.length > remainderPostsVisible

    const [showMoreButtonVisible, setShowMoreButtonVisible] = useState(hasMorePosts)

    const visibleRemainingPosts = hasMorePosts ? remainingPosts.slice(0, remainderPostsVisible) : remainingPosts

    useEffect(() => {
        const _remainderHeight = remainderRef.current.getBoundingClientRect().height

        setRemainderHeight(_remainderHeight)

        if(!hasMorePosts){
            setTimeout(() => {
                setShowMoreButtonVisible(false)
            },500)
        }

    }, [remainderPostsVisible])

    const showMore = () => {
        const _remainderPostsVisible = remainderPostsVisibleRef.current

        setRemainderPostsVisible(_remainderPostsVisible + 5)

        setTimeout(() => {
            recalcLocomotive()
        }, 1000)
    }

    return (
        <div className={'container deep'}>
            <ul className="blog-featured-three">
                {firstThreePosts.map((post, index) => {
                    return (
                        <BlogIndexPost {...post} key={index} />
                    )
                })}
            </ul>
            <h2>
                All Thoughts
            </h2>
            <div className={'expand-wrapper'} style={{
                height: remainderHeight ? remainderHeight + 'px' : undefined
            }}>
                <ul className="blog-remainder" ref={ref => remainderRef.current = ref}>
                    {visibleRemainingPosts.map((post, index) => {
                        return (
                            <BlogIndexPost
                                list
                                {...post}
                                key={index}
                                forceVisible={index >= paginate}
                            />
                        )
                    })}
                </ul>
            </div>
            <div className={'button-row'}>
                {showMoreButtonVisible &&
                    <Button
                        inverted
                        label={'Show more thoughts'}
                        onClick={showMore}
                        icon={'arrow-right'}
                        iconRight
                    />
                }
            </div>
        </div>
    )
}