import React, { Suspense, useContext, useEffect, useRef, useState } from 'react'
import { Canvas } from 'react-three-fiber'
import { useProgress, Html } from '@react-three/drei'
import { MainContext } from 'context/context'

import { useIsMobile } from 'utils'

import Scene from 'components/special/reflection/scene'

import { doScene } from 'components/special/metaboxes/scene'

function Loader() {
    const { progress } = useProgress()

    return (
        <Html center>
            
        </Html>
    )
}

export default ({
    titleLines,
    titleLinesMobile,
    mobileBackgroundImage
}) => {
    const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const isMobile = useIsMobile()

    const _lines = isMobile ? titleLinesMobile : titleLines

    const sceneType = 0
    const tempDisable = false

    const [hasMounted, setHasMounted] = useState(false)

    const elementRef = useRef()
    const canvasRef = useRef()

    useEffect(() => {
        if (elementRef && elementRef.current) {
            addInvertedHeaderSection(elementRef.current)

            setTimeout(() => {
                setHasMounted(true)
            }, 1000)

            setTimeout(() => {
                if (!tempDisable && sceneType) {
                    doScene(canvasRef.current)
                }
                
                if (window.cursorHandler) {
                    window.cursorHandler.attachListeners()
                }
            }, 100)

            setTimeout(() => {
                const cursorWrapper = document.getElementById('cursor-wrapper')
                cursorWrapper.classList.add('inverted')
            }, 300)
        }
    }, [elementRef])

    return (
        <div className={`inverted-cursor ${hasMounted ? 'mounted' : ''}`} ref={elementRef}>
            <div className="canvas-container">
                <div className={'text-cover'}>
                    <div data-scroll data-scroll-speed={1}>
                        <h1>
                            {_lines.map((line, index) => (
                                <span 
                                    key={index} 
                                    dangerouslySetInnerHTML={{ __html: line.line }} 
                                />
                            ))}
                        </h1>
                    </div>
                </div>
                { (isMobile && mobileBackgroundImage) ?
                    <div className={'mobile-bg'}>
                        <img src={mobileBackgroundImage.mediaItemUrl} />
                    </div>
                : !tempDisable ?
                    <>
                        {!sceneType ?
                            <Canvas 
                                concurrent 
                                shadowMap 
                                camera={{ position: [0, 0, 2], fov: 70 }}
                            >
                                <Suspense fallback={<Loader />}>
                                    <Scene />
                                </Suspense>
                                <ambientLight intensity={0.4} />
                            </Canvas>
                            :
                            <canvas className={'geo'} ref={canvasRef} />
                        }
                    </>
                : null}
            </div>
        </div>
    )
}