import React, { useContext } from "react"
import { MainContext } from "context/context"

import Button from "components/globals/button"

export default ({
    title,
    copy,
    button,
    openContactModal,
    backgroundColour,
    largeTitle,
}) => {
	const {
		setOpenMenuType,
	} = useContext(MainContext)

    const contClass = `CentredText-cont ${ backgroundColour === 'black' ? 'inverted-cursor' : '' } ${backgroundColour}`

    return (
        <div className={ contClass }>
            <div className={'container deep'}>
                <h1 className={ largeTitle ? 'large' : '' } dangerouslySetInnerHTML={{ __html: title }} />
                <div className="CentredText-copy" dangerouslySetInnerHTML={{ __html: copy }} />
                { button &&
                    <Button
                        label={ button.title }
                        url={ button.url }
                        type={ openContactModal ? 'button' : 'link' }
                        onClick={openContactModal ? () => setOpenMenuType('contact') : ''}
                        compact
                        inverted2
                    />
                }
            </div>
        </div>
    )
}
