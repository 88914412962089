import React, { useContext } from 'react'
import Link from "components/globals/link"
import { MainContext } from "context/context"

export default ({
    title,
    excerpt,
    featuredImage,
    categories,
    link
}) => {
    const featuredImageUrl = (() => {
        let response = null 

        if(!featuredImage || !featuredImage.node){
            return response
        }

        const {
            mediaItemUrl,
            mediaDetails
        } = featuredImage.node

        mediaDetails.sizes.forEach(size => {
            if(size.name === 'large'){
                response = size.sourceUrl
            }
        })

        if(!response){
            response = mediaItemUrl
        }

        return response
    })()

    return (
        <li className="blog-grid-list-item has-cursor-effect">
            <Link to={link}>
                <div className={'image-wrapper'}>
                    <div
                        className={'image inverted-cursor'}
                        style={{
                            backgroundImage: `url(${featuredImageUrl}`
                        }}
                    />
                </div>
                <p className={'category'}>
                    {categories.nodes[0].name}
                </p>
                <h3>
                    {title}
                    <i className="fe fe-arrow-right project-arrow" />
                </h3>
                <div className={'copy'} dangerouslySetInnerHTML={{ __html: excerpt }} />
            </Link>
        </li>
    )
}