import React from 'react'

export default ({
    left,
    right,
}) => {
    return (
        <div>
            <div className={'container deep'}>
                <div className={'left'}>
                    <div className={'h1 big'} dangerouslySetInnerHTML={{ __html: left }} />
                </div>
                <div className={'right'}>
                    <div className={'copy'} dangerouslySetInnerHTML={{ __html: right }} />
                </div>
            </div>
        </div>
    )
}