import React, { useEffect, useRef, useState, useContext } from 'react'
import { isEqual, throttle } from 'lodash'
import { MainContext } from "context/context"

import TextHero from 'components/flexibleContent/textHero'
import DiagonalScrollSection from 'components/flexibleContent/diagonalScrollSection'
import ProjectsGrid from 'components/flexibleContent/projectsGrid'
import BlogGrid from 'components/flexibleContent/blogGrid'
import ProjectSlideshow from 'components/flexibleContent/projectSlideshow'
import LogoGrid from 'components/flexibleContent/logoGrid'
import AccordionColumns from 'components/flexibleContent/accordionColumns'
import BlogIndex from 'components/flexibleContent/blogIndex'
import BlogHero from 'components/flexibleContent/blogHero'
import TwoColumnCopy from 'components/flexibleContent/twoColumnCopy'
import StatsList from 'components/flexibleContent/statsList'
import Slider from 'components/flexibleContent/slider'
import TeamSection from 'components/flexibleContent/teamSection'
import LabsHero from 'components/flexibleContent/labsHero'
import LabsProjectList from 'components/flexibleContent/labsProjectList'
import LabsDualTicker from 'components/flexibleContent/labsDualTicker'
import TextAndAccordion from 'components/flexibleContent/textAndAccordion'
import LabsExpandingItem from 'components/flexibleContent/labsExpandingItem'
import CentredText from 'components/flexibleContent/centredText'
import FormSection from 'components/flexibleContent/formSection'

const components = {
    TextHero,
    DiagonalScrollSection,
    ProjectsGrid,
    BlogGrid,
    ProjectSlideshow,
    LogoGrid,
    AccordionColumns,
    BlogIndex,
    BlogHero,
    TwoColumnCopy,
    StatsList,
    Slider,
    TeamSection,
    LabsHero,
    LabsProjectList,
    LabsDualTicker,
    TextAndAccordion,
    LabsExpandingItem,
    CentredText,
    FormSection
}

export default ({
    content,
    slug = '',
    postType
}) => {
    const {
        componentRefs,
        doScrollEvent,
    } = useContext(MainContext)

    const [visibleIndices, setVisibleIndices] = useState([])

    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []

        componentRefs.current.forEach((componentRef, index) => {
            const offset = componentRef.getBoundingClientRect().top

            let windowHeight = null

            if (typeof window != 'undefined') {
                windowHeight = window.innerHeight
            }

            if (offset < (windowHeight * 0.8)) {
                _visibleIndices.push(index)
            }
        })

        if (!isEqual(_visibleIndices, visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        }
    }

    const debouncedHandleScroll = useRef(throttle(handleScroll, 200))

    useEffect(() => {
        const { current } = debouncedHandleScroll

        setTimeout(() => {
            doScrollEvent(current)
        }, 2000)

        setTimeout(() => {
            current()
        }, 500)

        return () => window.removeEventListener('scroll', current)
    }, [])

    const addComponent = (ref, index) => {
        if (ref) {
            const _componentRefs = [...componentRefs.current]

            _componentRefs[index] = ref

            componentRefs.current = _componentRefs
        }
    }

    return (
        <div className={`post-type-${postType} ${slug} flexible-content`}>
            { content.flexibleContent.map((row, index) => {
                if (!row) {
                    return null
                }

                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_', '')
                const Tag = components[Component]

                const isScrolledIntoView = visibleIndices && visibleIndices.indexOf(index) !== -1

                const className = `${Component} ${isScrolledIntoView ? 'scrolled-into-view' : ''}`

                return (
                    <section
                        className={className}
                        ref={ref => addComponent(ref, index)}
                        key={index}
                        id={Component}
                        data-scroll-section
                    >
                        <Tag
                            {...row}
                            isFirst={!index}
                            index={index}
                            isScrolledIntoView={isScrolledIntoView}
                        />
                    </section>
                )
            })}
            <div 
                className='white-cover' 
                data-scroll-section
            />
        </div>
    )
}

